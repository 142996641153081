<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('orders.title') }}</h2>
    </div>
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end gap-3 w-full"
      >
        <div class="flex">
          <button
            class="btn bg-theme-31 text-white w-full py-2 px-2"
            @click="refresh"
          >
            <RefreshCwIcon class="w-5 h-5 mr-2 ml-2" />
            {{ i18n('common.refresh') }}
          </button>
        </div>
        <div class="flex sm:mt-0">
          <!-- <button
            class="btn bg-theme-31 text-white w-1/2 sm:w-auto mr-2 ml-2"
            @click="$router.push('/promo-code/new')"
          >
            <PlusIcon class="w-4 h-4 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button> -->
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary dark:text-white w-full"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
        </div>
        <div class="flex">
          <div class="dropdown w-full sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary dark:text-white w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>

          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2 mr-2">
            <button
              class="btn btn-outline-secondary dark:text-white w-full sm:w-auto"
              aria-expanded="false"
              :disabled="selected.length == 0"
              :class="selected.length > 0 ? 'dropdown-toggle' : ''"
            >
              {{ i18n('common.table.actions') }}
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  @click="doDisableAllSelected(selectAll)"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                >
                  <LockIcon class="w-4 h-4 mr-1 ml-1" />
                  {{ i18n('common.disable') }}
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <loading-spinner
          type="controller"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div ref="table" class="relative">
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              id="promoCodeTable"
              ref="tableRef"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10" id="ignore-6">#</th>
                  <!-- <th class="whitespace-nowrap text-center w-10" id="ignore-1">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="selectAll"
                      @click="select"
                    />
                  </th> -->
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap w-20"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index">
                  <td class="w-10">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <!-- <td class="w-10">
                    <div class="text-center" id="ignore-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="row.id"
                        v-model="selected"
                        :disabled="isExpired(row)"
                      />
                    </div>
                  </td> -->
                  <td class="text-center">
                    <router-link
                      :to="`/orders/${row.id}`"
                      class="whitespace-nowrap cursor-pointer truncate font-semibold underline hover:text-theme-10"
                    >
                      {{ presenter(row, 'id') }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="`/orders/${row.id}`"
                      class="whitespace-nowrap cursor-pointer font-semibold underline hover:text-theme-10"
                    >
                      {{ presenter(row, 'shopify_order_name') }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap cursor-pointer">
                      {{ presenterArr(row, 'providers_name') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterFullName(row, 'customer') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterEmail(row, 'customer') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-green-800">
                      {{ presenterWithCurrency(row, 'shopify_total_price') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-cyan-800">
                      {{ presenterWithCurrency(row, 'provider_total_price') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                  <!-- <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{
                        row.env
                          ? row.env === 'national'
                            ? isRTL
                              ? 'محلى'
                              : 'national'
                            : isRTL
                            ? 'دولى'
                            : 'international'
                          : isRTL
                          ? 'محلى'
                          : 'national'
                      }}
                    </div>
                  </td> -->
                  <td class="w-56">
                    <div
                      class="flex items-center justify-center gap-1 min-w-[2rem]"
                      :class="{
                        'text-theme-24':
                          row.status === 'rejected' || row.status === 'failed',
                        'text-green-600 dark:text-theme-30':
                          row.status === 'success',
                        'text-theme-11': row.status === 'resend_success',
                        'text-gray-600': row.status === 'processing'
                      }"
                    >
                      <!-- :class="{
                        'text-theme-24': !row.activated,
                        'text-theme-10 dark:text-theme-30': row.activated
                      }" -->
                      <CheckSquareIcon
                        class="min-w-[1.2rem] min-h-[1.2rem]"
                        v-if="row.status === 'success'"
                      />
                      <ArrowUpCircleIcon
                        class="min-w-[1.5rem] min-h-[1.5rem]"
                        v-else-if="row.status === 'resend_success'"
                      />
                      <XCircleIcon
                        class="min-w-[1.2rem] min-h-[1.2rem]"
                        v-else-if="
                          row.status === 'rejected' || row.status === 'failed'
                        "
                      />
                      <LoaderIcon
                        class="min-w-[1.2rem] min-h-[1.2rem]"
                        v-else-if="row.status === 'processing'"
                      />
                      <div>
                        {{ i18n(`common.${row.status}`) }}
                      </div>
                    </div>
                  </td>
                  <td class="table-report__action w-56" id="ignore-4">
                    <div class="flex justify-center items-center gap-1">
                      <!-- <a
                        href="javascript:;"
                        class="text-green-600 w-32 flex gap-1"
                        v-if="row.status !== 'success'"
                        @click="doResend({ id: row.id })"
                      >
                        <RefreshCwIcon class="w-3.5 h-3.5" />
                        {{ isRTL ? 'اعد ارسال الكود' : 'Resend Code' }}
                      </a> -->
                      <router-link
                        class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                        :to="`/orders/${row.id}`"
                      >
                        <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                      </router-link>
                      <!-- <a
                        v-if="!!row.rejection_reason"
                        class="mx-2 flex items-center gap-1"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#reason-modal-preview"
                        @click="
                          () => {
                            rejectionId = row.id
                            rejectionReason = row.rejection_reason
                          }
                        "
                      >
                        <InfoIcon class="w-4 h-4" />
                        {{ i18n('common.reason') }}
                      </a> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.promoCode')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            :isFirstPage="isFirstPage"
            :isLastPage="isLastPage"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <reason-modal
      @close="
        () => {
          rejectionId = ''
          rejectionReason = ''
        }
      "
      :reason="rejectionReason"
      :orderId="rejectionId"
    />
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import dayjs from 'dayjs'
import reasonModal from '@/components/modals/reason-modal.vue'

export default defineComponent({
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
    }
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const rejectionReason = ref('')
    const rejectionId = ref('')
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })
    return {
      selected,
      tabulator,
      selectAll,
      tableRef,
      rejectionReason,
      rejectionId
    }
  },
  data() {
    return {
      filterInput: [],
      columns: [
        {
          name: 'orderID',
          field: 'id',
          label: 'order.fields.orderId',
          align: 'center'
        },
        {
          name: 'orderNumber',
          field: 'shopify_order_name',
          label: 'order.fields.orderNumber',
          align: 'center'
        },
        {
          name: 'provider',
          field: 'provider',
          label: 'orders.fields.provider',
          align: 'center'
        },
        {
          name: 'buyerName',
          field: 'buyerName',
          label: 'orders.fields.buyerName',
          align: 'center'
        },
        {
          name: 'buyerEmail',
          field: 'buyerEmail',
          label: 'orders.fields.buyerEmail',
          align: 'center'
        },
        {
          name: 'shopifyTotalPrice',
          field: 'shopifyTotalPrice',
          label: 'orders.fields.shopifyTotalPrice',
          align: 'center'
        },
        {
          name: 'providerTotalPrice',
          field: 'providerTotalPrice',
          label: 'orders.fields.providerTotalPrice',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'orders.fields.createdAt',
          align: 'center'
        },
        // {
        //   name: 'region',
        //   field: 'region',
        //   label: 'orders.fields.region',
        //   align: 'center'
        // },
        {
          name: 'status',
          field: 'status',
          label: 'orders.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'orders/list/rows',
      currentPage: 'orders/list/currentPage',
      isFirstPage: 'orders/list/isFirstPage',
      isLastPage: 'orders/list/isLastPage',
      pagination: 'orders/list/pagination',
      loading: 'orders/list/loading'
      // destroyLoading: 'orders/destroy/loading'
    }),
    statusOptions() {
      return [
        { label: this.i18n('common.success'), value: 'success' },
        { label: this.i18n('common.failed'), value: 'failed' }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('orders.fields.shopifyId'),
          value: 'shopify_order_name',
          type: 'text'
        },
        {
          label: this.i18n('order.fields.exactBuyerName'),
          value: 'exact_buyer_name',
          type: 'text'
        },
        {
          label: this.i18n('order.fields.buyerName'),
          value: 'buyer_name',
          type: 'text'
        },
        {
          label: this.i18n('order.fields.buyerEmail'),
          value: 'buyer_email',
          type: 'text'
        }
      ]
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    isExpired() {
      return (row) => {
        return dayjs().isAfter(dayjs(row.endDate)) || row.status == 'disabled'
      }
    }
    // isExpired() {
    //   return dayjs().isAfter(dayjs('20 Aug, 2022'))
    // }
  },
  async mounted() {
    await this.doFetchFirstPage()
    // this.doMountTable(this.$refs.table)
  },
  methods: {
    ...mapActions({
      doFetch: 'orders/list/doFetch',
      doFetchNextPage: 'orders/list/doFetchNextPage',
      doFetchPreviousPage: 'orders/list/doFetchPreviousPage',
      doResend: 'orders/list/doResend',
      doChangePaginationCurrentPage:
        'orders/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'orders/list/doChangePaginationPageSize',
      doDisable: 'orders/list/doDisable',
      doDisableAllSelected: 'orders/list/doDisableAllSelected'
    }),
    async refresh() {
      await this.doFetchFirstPage()
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterFullName(row, fieldName) {
      return GenericModel.presenterFullName(row, fieldName)
    },
    presenterEmail(row, fieldName) {
      return GenericModel.presenterEmail(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterWithCurrency(row, fieldName) {
      const PRICE = GenericModel.presenterDecimal(row, fieldName)
      return row.currency === 'USD'
        ? this.language == 'ar'
          ? `${PRICE || 0} دولار`
          : `${PRICE || 0} USD`
        : this.language == 'ar'
        ? `${PRICE || 0} جنية`
        : `${PRICE || 0} EGP`
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          if (!this.isExpired(element)) {
            this.selected.push(element.id)
          }
        })
      }
    },
    openCreatePage() {
      this.$router.push('/offer/new')
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'promoCodeTable', 'promoCode')
    },
    onDownloadPdf() {
      $h.downloadPdf('#promoCodeTable', 'promoCode', [1, 9])
    },
    onPrint() {
      print({
        printable: 'promoCodeTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 230,
        ignoreElements: [
          // 'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        orderBy,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  },
  components: {
    'reason-modal': reasonModal
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
