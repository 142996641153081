<template>
  <div id="reason-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <InfoIcon class="w-16 h-16 text-theme-1 mx-auto mt-3 mb-5" />
            <div class="text-2xl lg:text-3xl text-gray-400 mb-3.5">
              {{ i18n(`common.orderId`) }} {{ orderId }}
            </div>
            <div class="text-black text-base lg:text-lg">
              {{ i18n('common.rejectionReason') }} : {{ reason }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center flex justify-end">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 ml-1 mr-1"
            >
              {{ i18n(`common.close`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: String
    },
    reason: {
      type: String,
      required: true
    },
    orderId: {
      required: true
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style></style>
